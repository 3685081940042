import {Component, OnInit} from '@angular/core';
import {Inject, makeStateKey, Optional, PLATFORM_ID, TransferState} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {FooterComponent} from "./components/footer/footer.component";
import {CookieConsentComponent} from "./components/cookie-consent/cookie-consent.component";
import {RouteMetaService} from "./services/route-meta.service";
import {ModalComponent} from "./components/modal/modal.component";
import {DATA_FROM_SERVER, DataFromServer} from "./providers/data-from-server";
import {WebsiteSiteDataDto} from "./contracts/website-site-data.dto";
import {isPlatformServer} from "@angular/common";
import {DataService} from "./providers/data.service";
import {LanguageService} from "./services/language.service";

const websiteData = makeStateKey<WebsiteSiteDataDto>('WebsiteData');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, FooterComponent, CookieConsentComponent, ModalComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
              private router: Router,
              private route: ActivatedRoute,
              private routeMeta: RouteMetaService,
              private transferState: TransferState,
              private dataService: DataService,
              private langService: LanguageService,
              @Inject(PLATFORM_ID) private platformId: any,
              @Optional() @Inject(DATA_FROM_SERVER) private dataFromServer?: DataFromServer) {

    this.langService.setDefaultLang();
    this.router.events.subscribe((event) => {
      if (isPlatformServer(this.platformId)) return;

      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.router.events.subscribe(async (val) => {
      if (val instanceof NavigationEnd) {
        const lang = this.router.url.split('/').filter(x => x.length > 0)[0];
        this.langService.useLanguage(lang);
      }
    });

    this.route.queryParamMap
      .pipe(takeUntilDestroyed())
      .subscribe((params: any) => {
        let lang = params.get('lang');
        this.langService.useLanguage(lang);
      });

    this.routeMeta.subscribeAndUpdate(this.router);
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(websiteData, this.dataFromServer);
    } else if (this.transferState.hasKey(websiteData)) {
      const wsData = this.transferState.get(websiteData, undefined);
      this.dataService.setData(wsData);
    }
  }


  ngOnInit() {
    if (isPlatformServer(this.platformId)) return;

    const lang = localStorage.getItem('_language');
    this.langService.useLanguage(lang);
  }

}
