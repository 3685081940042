<div class="hero-bg hero-bg-legal">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1>{{'legal.description' | translate }}</h1>
    </div>
  </div>
</div>
<div class="app-container" innerHTML="{{ 'legal.content' | translate }}"></div>

