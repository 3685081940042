import {Component} from '@angular/core';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {TranslateModule} from "@ngx-translate/core";
import {NewsItemComponent} from "../../components/news-item/news-item.component";
import {NgForOf} from "@angular/common";
import {NewsItemDto} from "../../contracts/news-item.dto";
import {NewsService} from '../../services/news.service';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, NewsItemComponent, NgForOf],
  templateUrl: './news.component.html'
})
export class NewsComponent {
  items: NewsItemDto[] = [];

  constructor(
    private newsService: NewsService
  ) {
    this.items = this.newsService.getNews().filter(x => x.private != true);
  }

}
