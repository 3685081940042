import {Component} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {CareersRoute} from '../../app.globals';
import {RouterLink} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-culture',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, RouterLink, CommonModule],
  templateUrl: './culture.component.html',
  styleUrl: './culture.component.scss'
})
export class CultureComponent {
  protected readonly CareersRoute = CareersRoute;
  public lang = this.translateService.currentLang;

  constructor(private translateService: TranslateService,
              public langService: LanguageService) {
    this.translateService.onLangChange.subscribe((params: any) => {
      this.lang = params.lang;
    });
  }

}
