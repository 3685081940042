import {Component} from '@angular/core';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NewsItemComponent} from "../../components/news-item/news-item.component";
import {NgIf} from "@angular/common";
import {NewsItemDto} from "../../contracts/news-item.dto";
import {RouterLink} from '@angular/router';
import {AboutRoute, CareersRoute, CultureRoute} from '../../app.globals';
import {NewsService} from '../../services/news.service';
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, TranslateModule, NavbarComponent, NewsItemComponent, NgIf],
  templateUrl: './home.component.html'
})
export class HomeComponent {
  latestNewsItem: NewsItemDto;
  protected readonly AboutRoute = AboutRoute;
  protected readonly CultureRoute = CultureRoute;
  protected readonly CareersRoute = CareersRoute;

  constructor(private newsService: NewsService,
              public langService: LanguageService,
              private translateService: TranslateService
  ) {
    const news = this.newsService.getNews(this.translateService.currentLang).filter(x => !x.private);
    if (news && news.length > 0) {
      this.latestNewsItem = news[0];
    }
  }

}
