import { Injectable } from "@angular/core";
import { JobItemDto } from "../contracts/jobItemDto";
import { JobGroupDto } from "../contracts/job-group.dto";

@Injectable({
    providedIn: 'root'
  })
  export class CareerService {
  items:JobGroupDto[] = [
    {
        name: "Software Development and Operations",
        items:[
            {
                title: "Java Developer",
                slug: 'java-developer',
                imgSrc: "/assets/images/release.webp"
            },
            {
                title: "Angular Developer",
                slug: 'angular-developer',
                imgSrc: "/assets/images/release.webp"
            },
            {
                title: "DevOps Consultant with German",
                slug: 'devops-german',
                imgSrc: "/assets/images/release.webp"
            },
            {
                title: ".NET Developer with Azure & IoT Knowledge",
                slug: 'net-developer-azure-iot',
                imgSrc: "/assets/images/release.webp"
            }
      ],
      description: "As a Developer, you’ll play a crucial role in shaping application logic and data models. You’ll ensure high-quality code through test-driven <b>development and automated testing</b>, manage <b>CI/CD pipelines</b>, and deploy applications to <b>Kubernetes</b>. Taking end-to-end responsibility for development, you’ll collaborate within an <b>Agile</b> team, engaging in iterative processes, effective communication, and comprehensive documentation to drive continuous improvement and project success. Your expertise in both backend and frontend development will help deliver innovative solutions for our business applications.</br></br>You’ll work with a diverse tech stack, including backend technologies like <b>Java, Quarkus, Python, Node.js</b>, and <b>.NET</b>, and frontend frameworks such as <b>Angular</b> and <b>Vue.js</b>, to create robust, scalable applications.</br></br>As a Cloud Computing Specialist, you’ll focus on leveraging cloud services like <b>Azure</b> and <b>AWS</b> to enhance application infrastructure. By designing and implementing <b>Infrastructure as Code (IaC) solutions</b> and utilizing <b>Kubernetes orchestration</b>, you’ll ensure seamless deployments and scalability. Your expertise will enable the team to innovate and optimize cloud-based applications, facilitating continuous integration and delivery while maintaining security and performance best practices. Together, we’ll create reliable, scalable, and efficient solutions tailored to our evolving business needs.</br></br>Additionally, you’ll engage in <b>ITIL-guided activities</b>, enhancing application reliability through proactive incident management, seamless change management, and insightful performance monitoring to optimize development efforts.",
      url: "https://careers.nttdata.ro/bmwtechworks/go/Software-Development-and-Operations/9376501/",
      image:"/assets/images/careers-card-1.webp"
    }, {
      name: 'Cloud Computing',
      items: [
        {
          title:  "AWS Cloud DevOps Engineer",
          slug: 'aws-cloud-devops',
          imgSrc: "/assets/images/release.webp"
        }
      ],
      description: "Cloud Computing is at the heart of our digital transformation. Our experts design secure, scalable infrastructures using cutting-edge technologies like <b>AWS, Azure, Kubernetes</b>, and <b>Infrastructure as Code (IaC)</b>. By enabling seamless integration and optimized performance, this team ensures we stay ahead in digital innovation. Be part of creating cloud solutions that drive the future of mobility.",
      url: "https://careers.nttdata.ro/bmwtechworks/go/Cloud-Computing/9376601/",
      image: "/assets/images/careers-card-2.webp"

    },{
      name:  "Data Science & Data Engineering",
      items: [
        {
          title:  "LLM Data Scientist",
          slug: 'llm-data-scientist',
          imgSrc: "/assets/images/release.webp"
        },  {
          title:  "Data Engineer",
          slug: 'data-engineer',
          imgSrc: "/assets/images/release.webp"
        }
      ],
      description: "As a Data Scientist and Engineer, you’ll play a pivotal role in creating and implementing advanced data models and algorithms while ensuring high-quality data through thorough validation and testing practices. You’ll oversee CI/CD processes for data workflows and deploy analytics solutions to cloud platforms, taking full ownership of both data architecture and analysis. Collaborating within an Agile team, you’ll engage in iterative development, maintain clear communication with team members and stakeholders, and document processes to support continuous improvement and project success. Your commitment to data preprocessing and model evaluation will drive innovative, data-driven solutions.</br></br>You’ll utilize a diverse tech stack, including programming languages like <b>Python</b> and <b>R</b>, machine learning frameworks such as <b>TensorFlow</b> and <b>PyTorch</b>, and data processing tools like <b>Apache Airflow</b> and <b>Kafka</b>, to develop scalable and efficient data infrastructures.",
      url: "https://careers.nttdata.ro/bmwtechworks/go/Data-Science-&-Data-Engineering/9376701/",
      image: "/assets/images/careers-card-3.webp"

    }, {
      name:  "Agile Transformation & E2E Management",
      items: [
        {
          title:  "Business Analyst with German",
          slug: 'business-analyst-german',
          imgSrc: "/assets/images/release.webp"
        }
      ],
      description: "Our <b>Agile Transformation</b> and <b>E2E Management</b> teams revolutionize how we work. By fostering collaboration, streamlining workflows, and delivering impactful results, they ensure seamless project execution from start to finish. Join us in driving process excellence and innovation across the organization.",
      url: "https://careers.nttdata.ro/bmwtechworks/go/Agile-Transformation-&-E2E-Management/9376801/",
      image: "/assets/images/careers-card-4.webp"

    },{
      name:  "Product Platforms",
      items: [
        {
          title:   "Integration Designer with Mulesoft",
          slug: 'integration-designer-mulesoft',
          imgSrc: "/assets/images/release.webp"
        },
        {
          title:    "EDI Consultant",
          slug: 'edi-consultant',
          imgSrc: "/assets/images/release.webp"
        }
      ],
      description: "As a Developer/Consultant, you’ll take on the responsibility of implementing and maintaining enterprise platforms like <b> SAP, Salesforce, MuleSoft</b>, and <b>EDI systems</b> to streamline business processes. This role involves customizing solutions, managing system integrations, and ensuring seamless data flow across various platforms. Your expertise in <b>ERP, CRM, cloud</b>, and <b>data exchange technologies</b> will enable efficient operations and elevate customer experiences.</br></br>Key tasks include <b>system migration, API integration,</b> and managing both <b>cloud-based and on-premise solutions</b>. Additionally, handling electronic data interchange(EDI) will automate B2B communication. With skills in platform configuration, integration architecture, and troubleshooting, you’ll ensure secure, scalable solutions that meet organizational needs.",
      url: "https://careers.nttdata.ro/bmwtechworks/go/Product-Platforms/9376901/",
      image: "/assets/images/careers-card-5.webp"

    },{
      name:  "Corporate Functions",
      items: [],
      description: "Corporate Functions form the foundation of BMW TechWorks Romania. From <b>HR and Finance</b> to <b>Legal</b> and <b>Office Administration</b>, these roles ensure smooth operations that empower our teams to innovate and excel. Contribute to building a strong, inclusive workplace where every detail matters.",
      url: "https://careers.nttdata.ro/bmwtechworks/go/Corporate-Functions/9377401/",
      image: "/assets/images/careers-card-6.webp"

    }
  ];

  getItems() {
    return this.items;
  }

  getFlatList():JobItemDto[] {
    const res:JobItemDto[] = [];

    this.items.forEach(x=> {
        res.push(...x.items);
    });

    return res;
  }
}
