<div class="hero-bg hero-bg-home">
  <app-navbar/>
  <header class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container  hero-text  mx-0 ">
      <div>
        <h1 class="new-line"> {{ 'home.description' | translate }}</h1>
      </div>
    </div>
  </header>
</div>
<div class="app-container mb-5 mt-5 pt-3">
  <div>
    <h2 style="text-transform: none; color: var(--bmw-color-dark-grey);">
      {{ 'home.content-title' | translate }}
    </h2>
    <div class="d-flex mt-4 ">
      <p class="new-line">
        {{ 'home.content' | translate }}
      </p>
    </div>
  </div>
</div>


<div class="row app-container mx-0 ">
  <div class=" col-12   col-lg-4 ps-0 mb-3">
    <div class="bmw-courses-item_link bmw-courses-image bmw-courses-img-1"
         [routerLink]="langService.getLangRoute(AboutRoute)">
      <div class="bmw-courses-item_bg"></div>
      <h3 class="bmw-courses-item_title-value">
        {{ 'home.card1' | translate }}
      </h3>
    </div>
  </div>

  <div class=" col-12  col-lg-4 ps-0 mb-3">
    <div class="bmw-courses-item_link bmw-courses-image bmw-courses-img-2"
         [routerLink]="langService.getLangRoute(CultureRoute)">
      <div class="bmw-courses-item_bg"></div>
      <h3 class="bmw-courses-item_title-value">
        {{ 'home.card2' | translate }}
      </h3>
    </div>
  </div>

  <div class=" col-12 col-lg-4 ps-0 mb-3">

    <div class="bmw-courses-item_link bmw-courses-image bmw-courses-img-3"
         [routerLink]="langService.getLangRoute(CareersRoute)">
      <div class="bmw-courses-item_bg"></div>
      <h3 class="bmw-courses-item_title-value">
        {{ 'home.card3' | translate }}
      </h3>
    </div>
  </div>
</div>

<div class="mt-4 news-container" *ngIf="latestNewsItem">

  <div class="app-container pt-5">
    <h3>{{ 'news.title' | translate }}</h3>
    <app-news-item [item]="latestNewsItem">
    </app-news-item>
  </div>

</div>
