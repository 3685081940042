import {Component, OnInit} from '@angular/core';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-policy',
  standalone: true,
  imports: [TranslateModule, NavbarComponent],
  templateUrl: './cookie-policy.component.html'
})
export class CookiePolicyComponent {

}
