<div class="hero-bg hero-bg-culture">
  <app-navbar/>
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1 class="new-line">{{ 'culture.description' | translate }}</h1>
    </div>
  </div>
</div>
<div class="app-container about-values pt-4 pb-5">
  <h2>{{ 'culture.content-title' | translate }}</h2>
  <p class="about-text pt-4 new-line">
    {{ 'culture.content' | translate }}
  </p>
</div>

<div class="row app-container our-values pb-5 pt-5 filp-card-row">
  <div class=" col-12 col-md-6 col-lg-6 col-xl-3 ps-0 mt-3  card-row row-item">
    <div class="box-item-flip" [ngClass]="lang=='de'?'flip-box-de':''">
      <div class="flip-box">
        <div class="flip-box-front flip-card-front-1">
          <div class="inner">
            <h2 class="d-flex justify-content-center">
              <b class="new-line flip-box-title">{{ 'culture.card1.title' | translate }} </b>
            </h2>
          </div>
        </div>
        <div class="flip-box-back flip-card-back-1">
          <div class="inner color-white">
            <p class="d-flex justify-content-center px-3">
              <b>
                {{ 'culture.card1.head-content' | translate }}
              </b>
            </p>
            <p class="px-3 new-line">
              {{ 'culture.card1.content' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" col-12  col-md-6 col-lg-6 col-xl-3 ps-0 mt-3 card-row row-item">
    <div class="box-item-flip">
      <div class="flip-box" [ngClass]="lang=='de'?'flip-box-de':''">
        <div class="flip-box-front flip-card-front-2">
          <div class="inner">
            <h2 class="d-flex justify-content-center ">
              <b class="new-line flip-box-title">{{ 'culture.card2.title' | translate }} </b>
            </h2>
          </div>
        </div>
        <div class="flip-box-back flip-card-back-2">
          <div class="inner color-white">
            <p class="d-flex justify-content-center px-3">
              <b>
                {{ 'culture.card2.head-content' | translate }}
              </b>
            </p>
            <p class="px-3 new-line ">
              {{ 'culture.card2.content' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" col-12  col-md-6 col-lg-6 col-xl-3 ps-0 mt-3  card-row row-item">
    <div class="box-item-flip">
      <div class="flip-box" [ngClass]="lang=='de'?'flip-box-de':''">
        <div class="flip-box-front flip-card-front-3">
          <div class="inner">
            <h2 class="d-flex justify-content-center">
              <b class="new-line flip-box-title">{{ 'culture.card3.title' | translate }} </b>
            </h2>
          </div>
        </div>
        <div class="flip-box-back flip-card-back-3">
          <div class="inner color-white">
            <p class="d-flex justify-content-center px-3">
              <b>
                {{ 'culture.card3.head-content' | translate }}
              </b>
            </p>
            <p class="px-3 new-line">
              {{ 'culture.card3.content' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" col-12  col-md-6 col-lg-6 col-xl-3 ps-0 mt-3  card-row row-item">
    <div class="box-item-flip">
      <div class="flip-box" [ngClass]="lang=='de'?'flip-box-de':''">
        <div class="flip-box-front flip-card-front-4">
          <div class="inner">
            <h2 class="d-flex justify-content-center">
              <b class="new-line flip-box-title">{{ 'culture.card4.title' | translate }} </b>
            </h2>
          </div>
        </div>
        <div class="flip-box-back flip-card-back-4">
          <div class="inner color-white">
            <p class="d-flex justify-content-center px-3">
              <b>
                {{ 'culture.card4.head-content' | translate }}
              </b>
            </p>
            <p class="px-3 new-line">
              {{ 'culture.card4.content' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="call-to-action-link-btn mt-3 about-button about-subtitles"
          [routerLink]="langService.getLangRoute(CareersRoute)">
    <span>      {{ 'button.join-button' | translate }}</span>
    >
  </button>
</div>
