import { Component } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { NavbarComponent } from "../../components/navbar/navbar.component";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, CommonModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {
  public lang = this.translateService.currentLang;
  constructor(private translateService: TranslateService, private router: Router) {
    this.translateService.onLangChange.subscribe((params: any) => {
      this.lang = params.lang;
    });
}


}
