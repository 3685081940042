import {Routes} from '@angular/router';
import {ContactComponent} from "./pages/contact/contact.component";
import {HomeComponent} from "./pages/home/home.component";
import {SeoDto} from "./contracts/seo.dto";
import {AboutComponent} from './pages/about/about.component';
import {CareersComponent} from './pages/careers/careers.component';
import {CultureComponent} from './pages/culture/culture.component';
import {CookiePolicyComponent} from './pages/cookie-policy/cookie-policy.component';
import {LegalComponent} from './pages/legal/legal.component';
import {UrlUtil} from "./utils/url.util";
import {PressReleasePageComponent} from './pages/press-release-page/press-release-page.component';
import {NewsComponent} from "./pages/news/news.component";

export const routes: Routes = [
  {
    path: ':lang/about',
    component: AboutComponent,
    data: {
      seo: {
        title: 'About Us',
        description: 'Introducing BMW Group TechWorks Romania, the European IT hub of BMW',
        ogUrl: UrlUtil.composeUrlRoute('about'),
        url: UrlUtil.composeUrlRoute('about'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/about-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'about',
    redirectTo: 'en/about',
    pathMatch: 'full'
  },
  {
    path: ':lang/contact',
    component: ContactComponent,
    data: {
      seo: {
        title: 'Contact',
        description: 'Contact page for BMW Group TechWorks Romania',
        ogUrl: UrlUtil.composeUrlRoute('contact'),
        url: UrlUtil.composeUrlRoute('contact'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/contact-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'contact',
    redirectTo: 'en/contact',
    pathMatch: 'full'
  },

  {
    path: 'careers',
    redirectTo: 'en/careers',
    pathMatch: 'full'
  },
  {
    path: ':lang/careers',
    component: CareersComponent,
    data: {
      seo: {
        title: 'Careers',
        description: 'Browse all our available job openings',
        ogUrl: UrlUtil.composeUrlRoute('careers'),
        url: UrlUtil.composeUrlRoute('careers'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/careers-page.webp')
      } as SeoDto
    }
  },

  {
    path: ':lang/culture',
    component: CultureComponent,
    data: {
      seo: {
        title: 'Our Culture and Values ',
        description: 'Discover our opportunities and  take the next career step with BMW TechWorks Romania',
        ogUrl: UrlUtil.composeUrlRoute('culture'),
        url: UrlUtil.composeUrlRoute('culture'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/culture-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'culture',
    redirectTo: 'en/culture',
    pathMatch: 'full'
  },

  {
    path: 'culture',
    redirectTo: 'en/cookies-policy',
    pathMatch: 'full'
  },
  {
    path: ':lang/cookies-policy',
    component: CookiePolicyComponent,
    data: {
      seo: {
        title: 'Privacy & Cookies Policy',
        description: 'Privacy & Cookies Policy',
      } as SeoDto
    }
  },

  {
    path: 'legal',
    redirectTo: 'en/legal',
    pathMatch: 'full'
  },
  {
    path: ':lang/legal',
    component: LegalComponent,
    data: {
      seo: {
        title: 'Legal information',
        description: 'Legal information',
      } as SeoDto
    }
  },

  {
    path: 'press',
    redirectTo: 'en/press',
    pathMatch: 'full'
  },
  {
    path: ':lang/press',
    component: PressReleasePageComponent,
    data: {
      seo: {
        title: 'Press Release Page',
        description: 'Press Release Page Desc',
      } as SeoDto
    }
  },


  {
    path: 'news',
    redirectTo: 'en/news',
    pathMatch: 'full'
  },
  {
    path: ':lang/news',
    component: NewsComponent,
    data: {
      seo: {
        title: 'BMW Group TechWorks Romania - News',
        description: 'Read new stories about the company and learn more about current technological trends and innovations',
        ogUrl: UrlUtil.composeUrlRoute('news'),
        url: UrlUtil.composeUrlRoute('news'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/news-page.webp')
      } as SeoDto
    }
  },


  {
    path: ':lang',
    component: HomeComponent,
    data: {
      seo: {
        title: 'BMW Group TechWorks Romania',
        description: 'BMW Group TechWorks Romania leads automotive innovation in partnership with the BMW Group, shaping the future of motion',
        ogUrl: UrlUtil.composeUrlRoute(''),
        url: UrlUtil.composeUrlRoute(''),
        ogImage: UrlUtil.composeUrlRoute('assets/images/homepage.webp')
      } as SeoDto
    }
  },
  {
    path: '',
    redirectTo: 'en',
    pathMatch: 'full'
  },
  {path: '', loadChildren: () => import('./pages/news-detail/news-detail.routes').then(m => m.routes)},
];
