<div class="hero-bg hero-bg-careers">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1 class="new-line">{{ 'careers.description' | translate }}</h1>
    </div>
  </div>
</div>
<div class="app-container pb-3">
  <h2>{{ 'careers.title' | translate }}</h2>
  <p class="mt-4 new-line">
    {{ 'careers.content' | translate }}
  </p>
</div>
  <div class="row app-container our-values pb-5 pt-5 filp-card-row">
  
    <ng-template ngFor let-item [ngForOf]="items" let-i="index" [ngForTrackBy]="trackByFn">
      <div class=" col-12  col-md-6 col-lg-6 col-xl-4 ps-0 mt-3 mb-3  card-row row-item">
        <app-job-group [job]="item" />
      </div>
    </ng-template>
  </div>


