import {Component} from "@angular/core";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {
  ContactRoute,
  HomeRoute,
  AboutRoute,
  CultureRoute,
  NewsRoute,
  CareersRoute,
  LegalRoute,
  CookiesPolicyRoute
} from "../../app.globals";
import {NgClass} from "@angular/common";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, TranslateModule, NgClass],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  protected readonly ContactRoute = ContactRoute;
  protected readonly HomeRoute = HomeRoute;
  protected readonly AboutRoute = AboutRoute;
  protected readonly CultureRoute = CultureRoute;
  protected readonly CareersRoute = CareersRoute;
  protected readonly CookiesPolicyRoute = CookiesPolicyRoute;
  protected readonly LegalRoute = LegalRoute;
  protected readonly NewsRoute = NewsRoute;
  public lang = this.translateService.currentLang;

  constructor(private translateService: TranslateService,
              public langService: LanguageService) {
    this.translateService.onLangChange.subscribe((params: any) => {
      this.lang = params.lang;
    });
  }

  changeLanguage(language: string) {
    this.langService.changeLanguage(language);
  }
}
